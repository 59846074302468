<template>
  <div>
    <div class="row">
      <div class="col-md-12 m-auto">
        <div class="row no-gutters">
          <div
            v-for="(widget, _wIndex) in keyServices[year]"
            :key="_wIndex"
            class="col-md-6 col-lg-4 col-sm-6 col-xl-4">
            <small-card-with-icon
              :icon-path="widget.icon ? widget.icon.relative_url : ''"
              :subtitle="widget.subtitle"
              :title="widget.title"
              :value="widget.description"
              class="mr-20"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="mt-70">
      <h4 class="text-primary mb-20">{{ resourceTitle[year].title }}</h4>
      <div class="row">
        <div v-for="(resource, _rIndex) in resources"
             :key="_rIndex"
             class="col-md-12 col-lg-7 mr-auto">
          <Resource
            :description1="resource.subtitle"
            :description2="resource.description"
            :date="resource.date"
            :icon-path="resource.icon.relative_url"
            :download-resource="resource"
            :title="resource.title"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Resource from '@/components/common/Resource'
import SmallCardWithIcon from '@/components/common/SmallCardWithIcon'
import UtilityMixin from '@/mixins/utility-mixin'

export default {
  name: 'CountryYearTabContent',
  components: {
    Resource,
    SmallCardWithIcon
  },
  mixins: [UtilityMixin],
  props: {
    keyServices: {
      type: Object,
      required: true
    },
    resourceTitle: {
      type: Object,
      required: true
    },
    resources: {
      type: Array,
      required: true
    },
    year: {
      type: [String, Number],
      required: true
    }
  }
}
</script>
