<template>
  <section class="mt-40">
    <bw-loader v-if="loading"/>
    <div v-if="!loading" class="container">
      <div class="row">
        <div class="col">
          <div>
            <CountryTab :countries="countries"/>
            <div class="tab-content mt-70">
              <div id="tab-1"
                   :class="{ active: activeTab === 'ALL' }"
                   class="tab-pane fade show" role="tabpanel">
                <all-country-tab-content/>
              </div>
              <div
                v-for="country in countries" :id="'country-tab-' + country.id"
                :key="country.id"
                :class="{ active: activeTab === country.public_code }"
                class="tab-pane fade show" role="tabpanel">
                <each-country-tab-content :key="country.id" :country="country"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import UtilityMixin from '../../../mixins/utility-mixin'

import EachCountryTabContent from '@/components/user/enabling-farmers/EachCountryTabContent'
import AllCountryTabContent from '@/components/user/enabling-farmers/AllCountryTabContent'
import CountryTab from '@/components/common/tab/CountryTab'
import BwLoader from '@/components/ui/BwLoader'
import _ from 'lodash'

export default {
  name: 'EnablingFarmer',
  mixins: [UtilityMixin],
  components: {
    BwLoader,
    EachCountryTabContent,
    AllCountryTabContent,
    CountryTab
  },
  data () {
    return {
      loading: false,
      activeTab: 'ALL'
    }
  },
  computed: {
    countries () {
      return _.sortBy(this.$store.getters.getNkgCountry, 'name')
    }
  },
  created () {
    this.getEnablingFarmerPageContent()
  },
  mounted () {
    this.activeTab = this.$route.query.tab || 'ALL'
  },
  methods: {
    async getEnablingFarmerPageContent () {
      this.loading = true
      await this.$store.dispatch('getEnablingFarmerContentsFromServer')
      this.loading = false
    }
  },
  watch: {
    '$route.query.tab' (newValue) {
      this.activeTab = newValue || 'ALL'
    }
  }
}
</script>

<style scoped>

</style>
