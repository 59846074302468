<template>
  <div>
    <h3 class="text-primary mt-40 mb-35 font-22">{{ chartContent.title }}</h3>
    <div class="row">
      <div class="col-sm-12 col-md-8 col-lg-9 highchart">
        <figure class="highcharts-figure">
          <div id="countries">
            <Chart v-if="chartContent.series.length > 0" :options="getChartConfig()"/>
          </div>
        </figure>
      </div>
      <div class="col-sm-12 col-md-4 col-lg-3">
        <small-card-with-icon
          v-for="(item, _i) in keyStatistics"
          :key="_i"
          :icon-path="item.icon ? item.icon.relative_url : ''"
          :subtitle="item.title"
          :value="item.subtitle"/>
      </div>
    </div>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue'
import SmallCardWithIcon from '@/components/common/SmallCardWithIcon'
import UtilityMixin from '@/mixins/utility-mixin'
import Highcharts from 'highcharts'

export default {
  name: 'CountryTabChart',
  components: {
    SmallCardWithIcon,
    Chart
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Peet’s coffee purchases under NKG Bloom'
    },
    keyStatistics: {
      type: Array,
      required: false
    },
    chartContent: {
      type: Object,
      required: true
    },
    chartTitle: {
      type: String,
      required: false,
      default: ''
    }
  },
  mixins: [UtilityMixin],
  computed: {},
  data () {
    return {}
  },
  methods: {
    getChartConfig () {
      Highcharts.setOptions({
        lang: {
          thousandsSep: ',',
          decimalPoint: '.'
        }
      })
      return {
        chart: {
          type: 'column'
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: this.chartContent.categories,
          crosshair: true,
          title: {
            text: this.chartContent.chart_horizontal_data
          }
        },
        yAxis: {
          min: 0,
          // max: 8000,
          tickInterval: 1000,
          title: {
            text: this.chartContent.chart_vertical_data
          },
          labels: {
            formatter: function () {
              return Number(this.value).toLocaleString()
            }
          }
        },
        navigation: {
          buttonOptions: {
            enabled: false
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size: 14px; font-family:AptiferLight">{point.key}</span><br/>',
          pointFormat: '<span style="color:{point.color}">●</span> <span style="font-family:AptiferLight">{series.name}: {point.y}</span><br/>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
            pointWidth: 26
          },
          series: {
            stacking: 'normal'
          }
          // column: {
          //   pointPadding: 0.2,
          //   borderWidth: 0
          // },
          // series: {
          //   pointWidth: 26
          // }
        },
        credits: {
          enabled: false
        },
        series: this.chartContent.series
      }
    }
  },
  created () {
    this.getChartConfig()
  }
}
</script>

<style scoped>

</style>
