<template>
  <div>
    <EachCountryTabContentTopMessage
      :description="greetingContent.description"
      :group-logo="greetingContent.photo ? greetingContent.photo.relative_url : ''"
      :title="greetingContent.title"
    />
    <CountryTabChart
      :chart-content="getChartContent()"
      :key-statistics="keyStatistics"
    />
    <h3 class="text-primary mt-5 font-22">{{ keyServiceGreeting.title }}</h3>
    <p class="font-18 text-pre-wrap">{{ keyServiceGreeting.description }}</p>
    <div class="tab-inside-tab mt-60 mb-60">
      <ul :id="country.public_code + 'yearTab'"
          class="nav nav-pills justify-content-center border-bottom" role="tablist">
        <li v-for="(item, _i) in tabbedYear" :key="_i" class="nav-item" role="presentation">
          <a
            :id="country.public_code + 'year-tab-' + _i + '-tab'"
            :aria-controls="'#' + country.public_code + 'year-tab-' + _i"
            :class="_i === 0 ? 'active':''"
            :href="'#' + country.public_code + 'year-tab-' + _i"
            class="nav-link font-18" data-toggle="pill">
            {{ item }}
          </a>
        </li>
      </ul>
      <!-- tab Content START -->
      <div id="yearTabContent" class="tab-content mt-50 text-left">
        <div
          v-for="(item, _i) in tabbedYear" :id="country.public_code + 'year-tab-' + _i"
          :key="_i"
          :aria-labelledby="country.public_code + 'year-tab'"
          :class="{ 'show active': _i === 0 }" class="tab-pane fade">
          <country-year-tab-content
            :key-services="keyServiceByYear"
            :resource-title="resourceTitle"
            :resources="allResources"
            :year="item"
          />
        </div>
      </div>
      <!-- tab Content END -->
    </div>
    <div>
      <div class="container">
        <div class="row table-wrapper">
          <div class="content-heading d-flex justify-content-between align-items-center">
            <h3 class="text-primary font-22">Farmer stories</h3>
          </div>
          <table class="table table-striped text-center">
            <thead>
            <tr>
              <th v-for="(item, _i) in tableHeaders" :key="_i"
                  @click="applyOrdering(item.db_representation, item.orderColumn)">
                {{ item.title }} <span v-show="orderColumnDbRepresentation === item.db_representation"
                                       :class="[orderBy === '-' ? 'descending': 'ascending']"></span> <br/>
                {{ item.subtitle }}
              </th>
            </tr>
            </thead>
            <tbody v-show="loading">
            <tr>
              <td :colspan="tableHeaders.length">
                <FBTableLoader :columns="5" :height="300" :primaryColor="'#d3d3d3'" :rows="5"
                               class="col-12"></FBTableLoader>
              </td>
            </tr>
            </tbody>
            <tbody v-for="(rowData, _rowIndex) in tableData" v-show="!loading" :key="_rowIndex">
            <tr>
              <td v-for="(item, _i) in rowData" :key="_i" :aria-controls="_rowIndex" :href="'#collapse'+_rowIndex"
                  aria-expanded="false"
                  data-toggle="collapse" role="button">
                <span v-if="rowData.length - 5 === _i">
                  <a v-if="item.photo">
                    <img v-if="!item.photo.relative_url" :src="require(`@/assets/img/icons/story.png`)"
                         alt="Icon"
                         class="resource-icon fit-object-cover">
                    <img v-if="item.photo.relative_url" :src="item.photo.relative_url | makeAbsUrl" alt="Icon"
                         class="resource-icon fit-object-cover">
                  </a>
                  <span v-else>-</span>
                </span>
                <span v-else v-html="item"></span>
              </td>
            </tr>
            <tr>
              <td :colspan="tableHeaders.length" class="p-0">
                <div :id="'collapse'+_rowIndex" class="collapse">
                  <div class="card card-body text-left">
                    <div class="row">
                      <div class="col-md-1"></div>
                      <div class="col-md-3">
                        <a v-if="rowData[0].photo">
                          <img v-if="!rowData[0].photo.relative_url" :src="require(`@/assets/img/icons/story.png`)"
                               alt="Icon"
                               class="farmer-stories-image fit-object-cover">
                          <img v-if="rowData[0].photo.relative_url" :src="rowData[0].photo.relative_url | makeAbsUrl"
                               alt="Icon"
                               class="farmer-stories-image fit-object-cover">
                        </a>
                      </div>
                      <div class="col-md-7">
                        <div class="row">
                          <div class="col-md-6">
                            <p class="font-weight-bold">Name:</p>
                          </div>
                          <div class="col-md-6">
                            <p>{{ rowData[0].title }}</p>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <p class="font-weight-bold">Region:</p>
                          </div>
                          <div class="col-md-6">
                            <p v-if="rowData[0].region_meta.is_deleted">
                              {{ rowData[0].region_meta.region_name }}
                            </p>
                            <p v-else>
                              <a
                                :href="'/farmers-region-group/detail/'+rowData[0].region_meta.id+'/region/'">
                                {{ rowData[0].region_meta.region_name }}
                              </a>
                            </p>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <p class="font-weight-bold">Size of coffee farm:</p>
                          </div>
                          <div class="col-md-6">
                            <p>
                              {{ rowData[0].subtitle }}
                            </p>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <p class="font-weight-bold">Joined NKG Bloom on:</p>
                          </div>
                          <div class="col-md-6">
                            <p>
                              {{ rowData[0].date|formattedDate('MMMM YYYY') }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-1"></div>
                    </div>
                    <div class="row mt-5">
                      <div class="col-md-1"></div>
                      <div class="col-md-10">
                        <span class="text-pre-wrap" v-html="rowData[0].description"></span>
                      </div>
                      <div class="col-md-1"></div>
                      <div class="col-md-1 offset-11">
                        <button :aria-controls="_rowIndex" :href="'#collapse'+_rowIndex"
                                aria-expanded="false"
                                class="btn btn-sm btn-success" data-toggle="collapse" role="button"
                                style="background-color: #006330">Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
            <tbody>
            <tr v-show="tableData.length === 0">
              <td :colspan="tableHeaders.length">No data available.</td>
            </tr>
            </tbody>
          </table>
          <div class="container pl-0 pr-0">
            <div class="">
              <div class="d-lg-flex d-block justify-content-between mt-15 mb-45">
                <div>
                  <select v-model="paginateDict.dataPerPage"
                          class="form-control admin-input justify-content-start" @change="getFarmerStoriesFromServer()">
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                  </select>
                </div>
                <div>
                  <nav aria-label="Page navigation example">
                    <ul class="pagination pagination-lg justify-content-end">
                      <li :class="{disabled: !paginateDict.firstPage, 'page-item':true}">
                        <a class="page-link" @click="getPaginatedData(paginateDict.firstPage)">First</a>
                      </li>
                      <li v-show="paginateDict.previousPageNumber > 0"
                          :class="{disabled: !paginateDict.previousUrl, 'page-item':true}">
                        <a class="page-link"
                           @click="getPaginatedData(paginateDict.previousUrl)">{{ paginateDict.previousPageNumber }}</a>
                      </li>
                      <li class="page-item disabled"><a class="page-link" href="#">{{
                          paginateDict.currentPageNumber
                        }}</a>
                      </li>
                      <li v-show="paginateDict.nextPageNumber > 0"
                          :class="{disabled: !paginateDict.nextUrl, 'page-item':true}">
                        <a class="page-link" @click="getPaginatedData(paginateDict.nextUrl)">{{
                            paginateDict.nextPageNumber
                          }}</a>
                      </li>
                      <li :class="{disabled: !paginateDict.lastPage, 'page-item':true}">
                        <a class="page-link" @click="getPaginatedData(paginateDict.lastPage)">Last</a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import CountryYearTabContent from '@/components/user/enabling-farmers/CountryYearTabContent'
import CountryTabChart from '@/components/user/enabling-farmers/CountryTabChart'
import EachCountryTabContentTopMessage from '@/components/common/tab/EachCountryTabContentTopMessage'
import _ from 'lodash'
import {FBTableLoader} from '@/plugins/content-loader/core/components'
import {validationMixin} from 'vuelidate'
import UtilityMixin from '@/mixins/utility-mixin'
import ResourceMixin from '@/mixins/common/resource'
import moment from 'moment'
import {ContentTypes} from '@/config/constants'

export default {
  name: 'EachCountryTabContent',
  components: {
    FBTableLoader,
    CountryYearTabContent,
    CountryTabChart,
    EachCountryTabContentTopMessage
  },
  props: {
    country: {
      required: true,
      type: Object
    }
  },
  computed: {
    countries() {
      return _.sortBy(this.$store.getters.getNkgCountry, 'name')
    },
    greetingContent() {
      return this.$store.getters.getEnablingFarmersCountryGreetingContent(this.country.name)
    },
    keyServiceGreeting() {
      return this.$store.getters.getEnablingFarmersCountryKeyServicesGreetings(this.country.name)
    },
    keyStatistics() {
      return this.$store.getters.getEnablingFarmersCountryKeyStatistics(this.country.name)
    },
    resourceTitle() {
      const _data = {}
      this.tabbedYear.map((item) => {
        _data[item] = this.$store.getters.getEnablingFarmerCountryResourceTitleByYear(
          this.country.name, JSON.stringify(item))
      })
      return _data
    },
    keyServiceByYear() {
      const _data = {}
      this.tabbedYear.map((item) => {
        _data[item] = this.$store.getters.getEnablingFarmerCountryKeyServicesByYear(
          this.country.name, JSON.stringify(item))
      })
      return _data
    },
    allResources() {
      return this.$store.getters.getEnablingFarmerCountryResourcesByYear(this.country.name)
    }
  },
  mixins: [validationMixin, UtilityMixin, ResourceMixin],
  data() {
    return {
      chartContent: {},
      tabbedYear: [
        ..._.range(2020, new Date().getFullYear() + 1, 1)
      ],
      chartConfig: {
        chart: {
          type: 'column'
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: [
            '2014',
            '2015',
            '2016',
            '2017',
            '2018',
            '2019',
            '2020'
          ],
          crosshair: true
        },
        yAxis: {
          min: 0,
          max: 8000,
          tickInterval: 1000,
          title: {
            text: 'Number of Farmers',
            margin: 100
          },
          labels: {
            formatter: function () {
              return this.value
            }
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
            pointWidth: 26
          }
        },
        legend: {
          enabled: false
        },
        navigation: {
          buttonOptions: {
            enabled: false
          }
        },
        series: [{
          name: 'Honduras',
          color: '#006330',
          data: [1000, 3000, 5000, 7000, 5000, 3000, 300]
        }]
      },
      apiPrefix: 'v1/content-item',
      tableHeaders: [
        {
          title: 'Photo',
          subtitle: '',
          db_representation: 'photo', // user for apply sort
          orderColumn: '' // apply ordering js level
        },
        {
          title: 'Name',
          subtitle: '',
          db_representation: 'title',
          orderColumn: 'title'
        },
        {
          title: 'Region',
          subtitle: '',
          db_representation: 'region_meta',
          orderColumn: 'region_meta'
        },
        {
          title: 'Size of coffee farm',
          subtitle: '',
          db_representation: 'subtitle',
          orderColumn: 'subtitle'
        },
        {
          title: 'Joined NKG Bloom on',
          subtitle: '',
          db_representation: 'date',
          orderColumn: 'date'
        }
      ],
      tableData: [],
      paginateDict: {
        nextUrl: null,
        previousUrl: null,
        currentPage: 1,
        totalPage: 1,
        firstPage: null,
        lastPage: null,
        remainingCount: 0,
        nextOffset: 0,
        totalCount: 0,
        dataPerPage: 5,
        previousPageNumber: 0,
        currentPageNumber: 1,
        nextPageNumber: 0
      },
      loading: false,
      orderColumnDbRepresentation: 'date',
      orderColumn: 'date'
    }
  },
  methods: {
    getChartContent() {
      const _content = this.$store.getters.getEnablingFarmersAllCountryChartContent
      const countries = _.sortBy(this.$store.getters.getNkgCountry, 'name')

      var countryNames = countries.map(function (item) {
        return {
          name: item.name,
          code: item.public_code
        }
      })

      const _chartConfig = {
        title: '',
        categories: [],
        series: []
      }
      if (!_.isEmpty(_content)) {
        // Chart title
        _chartConfig.title = _content.title ? _content.title : ''
        const chartData = JSON.parse(_content.chart_data)
        // const horizontalColumn = _content.chart_horizontal_data
        const activeTab = this.$route.query.tab || 'ALL'
        if (activeTab === 'ALL') {
          _chartConfig.series = chartData.series
          _chartConfig.categories = chartData.categories
        } else {
          let country = _.map(countryNames, function (o) {
            if (o.code === activeTab) return o
          })
          country = _.without(country, undefined)
          if (country.length > 0) {
            _chartConfig.chart_horizontal_data = _content.chart_horizontal_data
            _chartConfig.chart_vertical_data = _content.chart_vertical_data

            let series = []
            series = _.map(chartData.series, function (o) {
              if (o.name === country[0].name) return o
            })
            series = _.without(series, undefined)

            if (series.length) {
              series[0].color = '#006330' // explicitly show Green color for each country
            }
            _chartConfig.series = series

            _chartConfig.categories = chartData.categories
          }
        }
      }
      return _chartConfig
    },
    prepareTableContents() {
      try {
        this.tableData = []
        const farmerStories = [] // use for eliminate duplicate row from list
        let _content = this.$store.getters.getFarmerStoriesContents
        const orderBy = this.orderBy === '-' ? 'desc' : 'asc'
        _content = _.orderBy(_content, [this.orderColumn], [orderBy])
        // if (this.orderColumn === 'date') {
        //   _content = _.sortBy(_content, ['date'], [orderBy])
        // }
        for (const _data of _content) {
          // eliminate duplicate row from list
          if (_.filter(farmerStories, {id: _data.id}).length) {
            continue
          } else {
            farmerStories.push({id: _data.id})
          }
          const tempData = []
          tempData.push(_data)
          tempData.push(_data.title)
          if (_.get(_data, 'region_meta.is_deleted')) {
            tempData.push(_.get(_data, 'region_meta.region_name') || '')
          } else {
            tempData.push('<a href="/farmers-region-group/detail/' + _data.region_meta.id + '/region/">' + _data.region_meta.region_name + '</a>')
          }
          tempData.push(_data.subtitle)
          tempData.push(moment(_data.date).format('MMMM YYYY'))
          this.tableData.push(tempData)
        }
        return this.tableData
      } catch (e) {
        this.errorLog(e)
      }
    },
    generatePagination(data) {
      try {
        this.paginateDict.nextUrl = _.get(data, 'next') || null
        this.paginateDict.previousUrl = _.get(data, 'previous') || null
        this.paginateDict.totalPage = _.get(data, 'total_page') || 0
        this.paginateDict.nextOffset = _.get(data, 'next_offset') || 0
        this.paginateDict.currentPage = _.get(data, 'current_page') || 0
        this.paginateDict.totalCount = _.get(data, 'count') || 0
        this.paginateDict.remainingCount = _.get(data, 'remaining_count') || 0
        this.paginateDict.firstPage = _.get(data, 'first') || null
        this.paginateDict.lastPage = _.get(data, 'last') || null
        // Page number
        this.paginateDict.previousPageNumber = this.paginateDict.previousUrl ? this.paginateDict.currentPage - 1 : 0
        this.paginateDict.currentPageNumber = this.paginateDict.currentPage
        this.paginateDict.nextPageNumber = this.paginateDict.nextUrl ? this.paginateDict.currentPage + 1 : 0
      } catch (e) {
        this.errorLog(e)
      }
    },
    async getFarmerStoriesFromServer() {
      try {
        this.loading = true
        const limit = `limit=${this.paginateDict.dataPerPage}`
        const apiUrl = `/api/${this.apiPrefix}/?search=1&limit=${limit}&content_type=${ContentTypes.FARMER_STORIES}&country__public_code=${this.country.public_code}&sort=${this.orderBy}${this.orderColumnDbRepresentation}`
        const response = await this.$store.dispatch('getCountryWiseFarmerStoriesContentsFromServer', {apiUrl: apiUrl})
        const data = response.data
        if (data.success) {
          this.generatePagination(data)
          this.$store.commit('setFarmerStoriesContent', data.results)
          await this.prepareTableContents()
        } else {
          this.errorLog(data)
        }
        this.loading = false
      } catch (e) {
        this.errorLog(e)
      }
    },
    async getPaginatedData(apiUrl) {
      this.loading = true
      const response = await this.$store.dispatch('getUserResourceContentsFromServer', {apiUrl: apiUrl})
      const data = response.data
      if (data.success) {
        this.generatePagination(data)
        this.$store.commit('setUserResourceContent', data.results)
        await this.prepareTableContents()
      } else {
        this.errorLog(data)
      }
      this.loading = false
    },
    async applySearch() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$notify({
          title: '',
          text: 'Please select column.',
          type: 'error',
          duration: 5000
        })
      } else {
        if (this.searchDict.searchField.widget_type === 'text' || this.searchDict.searchField.widget_type === 'number') {
          try {
            this.loading = true
            const offset = 'offset=0'
            const limit = `limit=${this.paginateDict.dataPerPage}`
            const searchParams = `${this.searchDict.searchField.db_representation}=${this.searchDict.searchText}`
            const apiUrl = `/api/${this.apiPrefix}/?search=1&${offset}&${limit}&${searchParams}&content_type=${ContentTypes.FARMER_STORIES}&country__public_code=${this.country.public_code}&sort=${this.orderBy}${this.orderColumnDbRepresentation}`
            const response = await this.$store.dispatch('getFarmerStoriesContents', {apiUrl: apiUrl})
            const data = response.data
            if (data.success) {
              this.generatePagination(data)
              this.$store.commit('setFarmerStoriesContent', data.results)
              await this.prepareTableContents()
            } else {
              this.errorLog(data)
            }
            this.loading = false
          } catch (e) {
            this.errorLog(e)
          }
        }
        if (this.searchDict.searchField.widget_type === 'date') {
          try {
            this.loading = true
            const _fromDate = Date.parse(this.searchDict.date) - 86400000
            const _toDate = Date.parse(this.searchDict.date) + 86400000
            const offset = 'offset=0'
            const limit = `limit=${this.paginateDict.dataPerPage}`
            const searchKeys = _.split(this.searchDict.searchField.db_representation, ',')
            let searchParams = ''
            if (!_.isEmpty(searchKeys)) {
              const _from = searchKeys[0] || ''
              searchParams = `${_from}=${_fromDate},${_toDate}`
            }
            const apiUrl = `/api/${this.apiPrefix}/?search=1&${offset}&${limit}&${searchParams}&content_type=${ContentTypes.FARMER_STORIES}&country__public_code=${this.country.public_code}&sort=${this.orderBy}${this.orderColumnDbRepresentation}`
            const response = await this.$store.dispatch('getFarmerStoriesContents', {apiUrl: apiUrl})
            const data = response.data
            if (data.success) {
              this.generatePagination(data)
              this.$store.commit('setFarmerStoriesContent', data.results)
              await this.prepareTableContents()
            } else {
              this.errorLog(data)
            }
            this.loading = false
          } catch (e) {
            this.errorLog(e)
          }
        }
      }
    },
    async applyOrdering(fieldName, orderColumn) {
      if (this.orderBy === '-') {
        this.orderBy = ''
      } else {
        this.orderBy = '-'
      }

      this.orderColumn = orderColumn
      this.orderColumnDbRepresentation = fieldName
      await this.getFarmerStoriesFromServer()
    }
  },
  async created() {
    await this.getFarmerStoriesFromServer()
  }
}
</script>

<style lang="scss" scoped>
.nav-item {
  a {
    border-radius: 0;
  }
}

.farmer-stories-image {
  width: 200px !important;
  height: 180px !important;
}

.fit-object-cover {
  object-fit: cover;
}
</style>
