<template>
  <div>
    <CountryTabContentTopMessage
      :description="greetingContent.description"
      :title="greetingContent.title"
    />
    <CountryTabChart
      :chart-content="getChartContent()"
      :key-statistics="keyStatistics"
    />
    <h4 class="text-primary mt-5 font-22">
      {{ keyServiceGreeting.title }}
    </h4>
    <p class="font-18 text-pre-wrap">{{ keyServiceGreeting.description }}</p>
    <div class="row mt-40 flex-wrap">
      <div
        v-for="(item, _i) in keyServices" :key="_i"
        class="col-sm-8 col-md-6 col-lg-4 col-xl-3 offset-sm-2 offset-md-0 offset-lg-0 offset-xl-0">
        <small-card-with-icon :icon-path="item.icon ? item.icon.relative_url : ''" :subtitle="item.subtitle"
                              :title="item.title" :value="''"/>
      </div>
    </div>
  </div>
</template>

<script>

import CountryTabChart from '@/components/user/enabling-farmers/CountryTabChart'
import SmallCardWithIcon from '@/components/common/SmallCardWithIcon'
import CountryTabContentTopMessage from '@/components/common/tab/CountryTabContentTopMessage'
import UtilityMixin from '@/mixins/utility-mixin'
import _ from 'lodash'

export default {
  name: 'AllCountryTabContent',
  components: {
    SmallCardWithIcon,
    CountryTabChart,
    CountryTabContentTopMessage
  },
  mixins: [UtilityMixin],
  computed: {
    countries () {
      return _.sortBy(this.$store.getters.getNkgCountry, 'name')
    },
    greetingContent () {
      return this.$store.getters.getEnablingFarmersAllCountryGreetingContent
    },
    keyServices () {
      return this.$store.getters.getEnablingFarmersAllCountryKeyServices
    },
    keyStatistics () {
      return this.$store.getters.getEnablingFarmersAllCountryKeyStatistics
    },
    keyServiceGreeting () {
      return this.$store.getters.getEnablingFarmersAllCountryKeyServicesGreetings
    }
  },
  data () {
    return {}
  },
  methods: {
    getChartContent () {
      const _content = this.$store.getters.getEnablingFarmersAllCountryChartContent
      const countries = _.sortBy(this.$store.getters.getNkgCountry, 'name')

      var countryNames = countries.map(function (item) {
        return {
          name: item.name,
          code: item.public_code
        }
      })

      const _chartConfig = {
        title: '',
        categories: [],
        series: [],
        chart_horizontal_data: '',
        chart_vertical_data: ''
      }
      if (!this.$_.isEmpty(_content)) {
        // Chart title
        _chartConfig.title = _content.title ? _content.title : ''
        const chartData = JSON.parse(_content.chart_data)
        // const horizontalColumn = _content.chart_horizontal_data
        const activeTab = this.$route.query.tab || 'ALL'
        if (activeTab === 'ALL') {
          _chartConfig.series = chartData.series
          _chartConfig.categories = chartData.categories
          _chartConfig.chart_horizontal_data = _content.chart_horizontal_data
          _chartConfig.chart_vertical_data = _content.chart_vertical_data
        } else {
          let country = this.$_.map(countryNames, function (o) {
            if (o.code === activeTab) return o
          })
          country = this.$_.without(country, undefined)
          if (country.length > 0) {
            _chartConfig.chart_horizontal_data = _content.chart_horizontal_data
            _chartConfig.chart_vertical_data = _content.chart_vertical_data
            _chartConfig.series = chartData.series
            _chartConfig.categories = chartData.categories
          }
        }
      }
      return _chartConfig
    }
  }
}
</script>

<style scoped>

</style>
