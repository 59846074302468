<template>
  <enabling-farmer/>
</template>

<script>
import EnablingFarmer from '@/components/user/enabling-farmers/EnablingFarmer'

export default {
  name: 'EnablingFarmerView',
  components: { EnablingFarmer }
}
</script>

<style scoped>

</style>
